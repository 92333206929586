import echarts from "@/util/vo-echarts";
import device from "@/api/device";
//import * as echarts from "echarts";
//import * as echarts from 'echarts/core';

const graphic = require("echarts/lib/util/graphic");
import store from "@/store";

const voltageData = {
  color: [store.state.theme.curveColor],
  xAxis: {
    show: false,
    type: "category",
    boundaryGap: false,
    data: ["1", "2", "3", "4", "5", "6", "7", "8"]
  },
  yAxis: {
    show: false,
    type: "value"
  },
  grid: { bottom: 4, left: 22, right: 22, top: 22 },
  series: [
    {
      data: [1.3, 1.7, 3.3, 4, 2.0, 2.9, 3.6, 2],
      type: "line",
      smooth: true,
      symbol: "circle",
      symbolSize: 8,
      label: {
        show: true,
        position: "top",
        formatter: function(a) {
          // console.log(`c = ${c}`);
          return `${a.value.toFixed(2)}W`;
        },
        color: store.state.theme.curveColor,
        fontSize: 10,
        distance: 4
      },
      areaStyle: {
        color: graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: store.state.theme.curveLineColor[0] //c0dee2
          },
          {
            offset: 1,
            color: store.state.theme.curveLineColor[1] //f6fafa
          }
        ])
      }
    }
  ]
};

export function chartConfig() {
  return {
    color: [store.state.theme.curveColor],
    xAxis: {
      show: false,
      type: "category",
      boundaryGap: false,
      data: ["1", "2", "3", "4", "5", "6", "7", "8"]
    },
    yAxis: {
      show: false,
      type: "value"
    },
    grid: { bottom: 4, left: 22, right: 22, top: 22 },
    series: [
      {
        data: [2, 2, 2, 2, 2, 2, 2, 2],
        type: "line",
        smooth: true,
        symbol: "circle",
        symbolSize: 8,
        label: {
          show: true,
          position: "top",
          formatter: function(a) {
            // console.log(`c = ${c}`);
            let value = a.value;
            return `${a.value.toFixed(2)}W`;
          },
          color: store.state.theme.curveColor,
          fontSize: 10,
          distance: 4
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: store.state.theme.curveLineColor[0] //c0dee2
            },
            {
              offset: 1,
              color: store.state.theme.curveLineColor[1] //f6fafa
            }
          ])
        }
      }
    ]
  };
}

export function d019ChartConfig(deviceType, currentLevel) {
  const defaultChartConfig = chartConfig();
  if (deviceType === "D019") {
    switch (currentLevel) {
      case 1:
        const color = "rgba(255, 140, 0, 1)";
        const curveColorLinearGradient = ["rgba(255, 140, 0, 1)", "rgba(255, 140, 0, 0.2)"];
        defaultChartConfig.color[0] = color;
        defaultChartConfig.series[0].label.color = color;
        defaultChartConfig.series[0].areaStyle.color = graphic.LinearGradient(
          0,
          0,
          0,
          1,
          [
            {
              offset: 0,
              color: curveColorLinearGradient[0] //c0dee2
            },
            {
              offset: 1,
              color: curveColorLinearGradient[1] //f6fafa
            }
          ]
        );
        break;
      default:
        break;
    }
  }
  return defaultChartConfig;
}

export { voltageData };
