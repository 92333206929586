var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"header"},[_c('van-icon',{staticClass:"header-return",attrs:{"name":"arrow-left","size":"25","color":"#bcbec0"},on:{"click":function($event){return _vm.onClickHeaderReturn()}}}),_vm._v("\n    History\n  ")],1),_c('div',{staticClass:"content"},[_c('van-pull-refresh',{attrs:{"pulling-text":"Pull down to refresh","loading-text":"Loading...","success-text":"Refresh success","loosing-text":"Release to refresh"},on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"No more data","loading-text":"Loading more...","error":_vm.error,"error-text":"Load fail! Click reload"},on:{"update:error":function($event){_vm.error=$event},"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.historyList),function(item,index){return _c('div',{key:index,staticClass:"history-item"},[_c('div',{staticClass:"history-header"},[_c('div',{staticClass:"header-title"},[_vm._v(_vm._s(item.modeName))]),_c('div',{staticClass:"header-time"},[_vm._v(_vm._s(item.createTime))])]),_c('div',{staticClass:"item-split"}),_c('div',{staticClass:"history-content"},[(false)?_c('div',{staticClass:"history-row"},[_c('div',{staticClass:"history-left-title"},[_vm._v("NFC Settings")]),_c('div',{staticClass:"history-right-value"},[_vm._v("\n                "+_vm._s(item.isSupportNfc ? `ON` : `OFF`)+"\n              ")])]):_vm._e(),(false)?_c('div',{staticClass:"history-row"},[_c('div',{staticClass:"history-left-title"},[_vm._v("Preheat")]),_c('div',{staticClass:"history-right-value"},[_vm._v("\n                "+_vm._s(item.isSupportPreheat
                    ? `${_vm.calcHistoryPower(
                        item.preheatVoltage,
                        item.resistance,
                        item.maxPower
                      ).toFixed(2)}w, ${(item.preheatTime / 1000).toFixed(
                        1
                      )}s`
                    : `OFF`)+"\n              ")])]):_vm._e(),(item.tagId)?_c('div',{staticClass:"history-row"},[_c('div',{staticClass:"history-left-title"},[_vm._v("ID")]),_c('div',{staticClass:"history-right-value"},[_vm._v("\n                "+_vm._s(item.tagId)+"\n              ")])]):_vm._e(),(item.modelType === 1)?_c('div',{staticClass:"history-row"},[_c('div',{staticClass:"history-left-title"},[_vm._v("Device Model")]),_c('div',{staticClass:"history-right-value"},[_vm._v("\n                "+_vm._s(item.deviceType)+"\n              ")])]):_vm._e(),_c('div',{staticClass:"history-row"},[_c('div',{staticClass:"history-left-title"},[_vm._v("Connector Model")]),_c('div',{staticClass:"history-right-value",style:(item.modelType === 1 ? ``:`color:#3fa7bc`)},[_vm._v("\n                "+_vm._s(item.modelType === 1 ? 'NFC Connect': 'OMNI Hub')+"\n              ")])]),_c('div',{staticClass:"history-column"},[_c('div',{staticClass:"history-left-title"},[_vm._v("Power Curve")]),_c('div',[_c('div',[_vm._v(_vm._s(item.diyVoltages))]),_c('HistoryCurve',{attrs:{"curve-color":_vm.curveColor,"curve-line-color":_vm.curveLineColor,"power":item.power,"max-power":item.maxPower,"resistance":item.resistance}})],1)]),_c('van-button',{staticClass:"history-button-apply",attrs:{"type":"default","color":_vm.syncButtonColor},on:{"click":function($event){return _vm.onClickApply(item)}}},[_vm._v("Apply")])],1)])}),0)],1)],1),_c('div',{staticClass:"footer"})])
}
var staticRenderFns = []

export { render, staticRenderFns }