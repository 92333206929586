<template>
  <div class="container">
    <div class="header">
      <van-icon
        class="header-return"
        name="arrow-left"
        size="25"
        color="#bcbec0"
        @click="onClickHeaderReturn()"
      />
      History
    </div>
    <div class="content">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        pulling-text="Pull down to refresh"
        loading-text="Loading..."
        success-text="Refresh success"
        loosing-text="Release to refresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="No more data"
          loading-text="Loading more..."
          :error.sync="error"
          error-text="Load fail! Click reload"
          @load="onLoad"
        >
          <div
            class="history-item"
            v-for="(item, index) in historyList"
            :key="index"
          >
            <div class="history-header" >
              <div class="header-title">{{ item.modeName }}</div>
              <div class="header-time">{{ item.createTime }}</div>
            </div>
            <div class="item-split"></div>
            <div class="history-content">
              <div class="history-row" v-if="false">
                <div class="history-left-title">NFC Settings</div>
                <div class="history-right-value">
                  {{ item.isSupportNfc ? `ON` : `OFF` }}
                </div>
              </div>
              <div class="history-row" v-if="false">
                <div class="history-left-title">Preheat</div>
                <div class="history-right-value">
                  {{
                    item.isSupportPreheat
                      ? `${calcHistoryPower(
                          item.preheatVoltage,
                          item.resistance,
                          item.maxPower
                        ).toFixed(2)}w, ${(item.preheatTime / 1000).toFixed(
                          1
                        )}s`
                      : `OFF`
                  }}
                </div>
              </div>

              <div class="history-row"  v-if="item.tagId">
                <div class="history-left-title">ID</div>
                <div class="history-right-value">
                  {{ item.tagId  }}
                </div>
              </div>

              <div class="history-row"  v-if="item.modelType === 1">
                <div class="history-left-title">Device Model</div>
                <div class="history-right-value">
                  {{ item.deviceType }}
                </div>
              </div>
              <div class="history-row">
                <div class="history-left-title">Connector Model</div>
                <div class="history-right-value" :style="item.modelType === 1 ? ``:`color:#3fa7bc`">
                  {{ item.modelType === 1 ? 'NFC Connect': 'OMNI Hub' }}
                </div>
              </div>

              <div class="history-column">
                <div class="history-left-title">Power Curve</div>
                <div >
                  <div>{{item.diyVoltages}}</div>
                  <HistoryCurve :curve-color="curveColor"
                      :curve-line-color="curveLineColor"
                      :power="item.power" :max-power="item.maxPower" :resistance="item.resistance"/>
                </div>
              </div>


              <van-button
                class="history-button-apply"
                type="default"
                  :color="syncButtonColor"
                @click="onClickApply(item)"
                >Apply</van-button
              >
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import log from "@/util/log";
import { WriterSetting } from "@/bluetooth/BluetoothData";
import { calcMaxPower, calcPower, calcVoltageFloorInt } from "../util/utils";
import HistoryCurve from "@/views/HistoryCurve.vue";
import { mapState } from "vuex";

export default {
  name: "History",
  components: { HistoryCurve },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      error: false,
      historyList: [],
      pageNum: 1,
      pageSize: 10
    };
  },
  created() {},
  async mounted() {},
  computed: {
    ...mapState("theme", ["curveColor","curveLineColor","syncButtonColor"]),
  },
  methods: {
    onClickHeaderReturn() {
      this.$router.go(-1);
    },
    onClickApply(item) {
      const writerSetting = new WriterSetting();
      Object.assign(writerSetting, item);
      const name = this.$route.params.modelType === 1 ? 'Connect': 'NewHome'
      this.$router.replace({
        name,
        params: {
          writerSetting
        }
      });
    },
    async onRefresh() {
      this.refreshing = true;
      this.onLoad();
    },
    async onLoad() {
      //如果是刷新
      if (this.refreshing) {
        this.pageNum = 1;
        this.historyList = [];
      }
      try {
        let response = await this.$api.User.selectUploadHistory({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          //modelType: this.$route.params.modelType || 0
        });
        const history = response.data;
        const total = response.total;
        // log(history);
        this.historyList = this.historyList.concat(
          history.map(item => {
            const setting = new WriterSetting();
            setting.id = item.id;
            setting.diyVoltage = item.heatingVoltage
              .split(",")
              .map(item => parseInt(item));
            setting.isSupportNfc = item.nfcSettings === 1;
            setting.isSupportPreheat = item.preheatSetting === 1;
            setting.modeName = item.modeName;
            setting.preheatTime = item.preheatTime;
            setting.preheatVoltage = item.preheatVoltage;
            setting.createTime = item.createTime;
            setting.resistance = item.resistance;
            setting.tagId = item.tagId
            setting.deviceType = item.deviceType ? item.deviceType: "Unknown Device"

            const diyVoltage = item.heatingVoltage.split(",").map(voltage => parseInt(voltage))
            const limitMaxVoltage = calcVoltageFloorInt(item.maxPower, item.resistance)
            const maxPower = calcMaxPower(limitMaxVoltage, item.resistance)
            setting.maxPower = maxPower;
            setting.diyVoltage = diyVoltage
            setting.power = diyVoltage.map(voltage => parseFloat(calcPower(voltage, item.resistance, maxPower).toFixed(2)))

            setting.originDiyVoltage = diyVoltage
            setting.originPreheatVoltage = item.preheatVoltage
            setting.originPreheatTime = item.preheatTime;
            setting.originIsSupportNfc = item.isSupportNfc;
            setting.originIsSupportPreheat = item.isSupportPreheat;
            setting.modelType = item.modelType
            return setting;
          })
        );

        //刷新结束
        if (this.refreshing) {
          this.refreshing = false;
        } else {
          this.loading = false;
        }
        if (this.historyList.length >= total) {
          this.finished = true;
        }
        this.pageNum += 1;
      } catch (e) {
        console.log(e)
        this.loading = false;
        this.error = true;
      }
    },
    calcHistoryPower(voltage, r, maxPower) {
      return calcPower(voltage, r, maxPower);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  background: #FFFFFF;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 60px;
    min-height: 60px;
    background: #f1f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #545554;

    .header-return {
      position: absolute;
      left: 10px;
    }
  }

  .content {
    height: 100%;
    overflow-y: auto;
    background: #f6f6f6;

    // .history-item:last-child {
    //   margin-bottom: 16px;
    // }

    .history-item {
      margin-bottom: 10px;
      background: #ffffff;

      .history-header {
        display: flex;
        align-items: center;
        padding: 16px 16px;
        border-bottom: 1px solid #eeeeee;

        .header-title {
          font-size: 16px;
          font-weight: bold;
          color: #555555;
        }

        .header-time {
          font-size: 14px;
          color: #999999;
          margin-left: auto;
        }
      }

      .history-content {
        display: flex;
        flex-direction: column;
        padding: 0 16px 16px 16px;

        .history-row {
          display: flex;
          margin-top: 16px;

          .history-left-title {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }

          .history-right-value {
            max-width: 55%;
            font-size: 14px;
            font-weight: 400;
            color: #555555;
            margin-left: auto;
            text-align: right;
          }
        }

        .history-column {
          display: flex;
          flex-direction: column;
          margin-top: 16px;

          .history-left-title {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }
        .history-button-apply {
          height: 30px;
          margin: 16px 0 16px auto;
          background: #3fa7bc;
          color: white;
          border: none;
          border-radius: 8px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
