<script>
import { chartConfig, d019ChartConfig, voltageData } from "@/config/echarts-data";
import { calcHistoryPower, calcMaxPower, calcPower, calcVoltageFloorInt } from "@/util/utils";
import { mapState } from "vuex";

const graphic = require("echarts/lib/util/graphic");

export default {
  name: "HistoryCurve",
  props: {
    power: {
      type: Array,
      default: () => []
    },
    maxPower: {
      type: Number,
      default: 10
    },
    resistance: {
      type: Number,
      default: 1.2
    },
    curveColor: {
      type:String,
      default:'#86A1A9'
    },
    curveLineColor: {
      type:Array,
      default:()=> {
        return ["rgba(192, 222, 226, 1)", "rgba(246, 250, 250, 1)"]
      }
    },
    deviceLevel: {
      type:Number,
      default: 0
    }

  },
  data() {
    return {
      curveChart: null
    };
  },
  mounted() {
    this.refreshVoltageCurve()
  },
  methods: {
    onWindowSizeChanged() {
      this.curveChart.resize();
    },
    refreshVoltageCurve() {
      this.$nextTick(() => {
        if (this.$refs.voltageOne) {
          this.curveChart = this.$echarts.init(this.$refs.voltageOne);
          window.addEventListener("resize", this.onWindowSizeChanged);
        }
        const power = this.power;
        if (!power) {
          return;
        }

        const voltageData = d019ChartConfig(this.deviceLevel >0 ? 'D019': '',this.deviceLevel);
        voltageData.yAxis.min = 0.01;
        voltageData.series[0].data = power
        if (this.deviceLevel <= 0) {

          voltageData.color[0] = this.curveColor;
          voltageData.series[0].label.color = this.curveColor;
          voltageData.series[0].areaStyle.color = graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: this.curveLineColor[0] //c0dee2
                },
                {
                  offset: 1,
                  color: this.curveLineColor[1] //f6fafa
                }
              ]
          );
        }
        this.curveChart.setOption(voltageData, true);
      });
    }
  },
  computed: {
  },
  watch: {
    power(value) {
      this.refreshVoltageCurve()
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowSizeChanged);
    this.curveChart.dispose()
    this.curveChart = null
  }
};
</script>

<template>
  <!-- 曲线1 -->
  <div
      id="voltageOne"
      ref="voltageOne"
      class="voltage-one"
      style="width:100%;height:calc(16vh)"
  ></div>
</template>

<style scoped lang="less">

.voltage-one {
}
</style>